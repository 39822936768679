import React from "react";
import "./contact-section.scss";
import ContactForm from "./ContactForm";
function ContactSection() {
  return (
    <section className={"contact"} id="contact">
      <div className={"container contact-container"}>
        <ContactForm />
      </div>
    </section>
  );
}

export default ContactSection;
