import React from 'react';
import { useTranslation } from 'react-i18next';
import './about-section.scss';
import Specialties from './Specialties';
import { motion } from 'framer-motion';

const specialtiesData = [
  {
    id: 'hypertension',
    icon: `${process.env.PUBLIC_URL}/hypertension.png`,
  },
  {
    id: 'generalMedicine',
    icon: `${process.env.PUBLIC_URL}/gp.png`,
  },
  {
    id: 'visionChecks',
    icon: `${process.env.PUBLIC_URL}/vision.png`,
  },
  {
    id: 'bloodPressureMonitoring',
    icon: `${process.env.PUBLIC_URL}/bloodpressure.png`,
  },
];

function AboutSection() {
  const { t } = useTranslation();
  
  return (
    <motion.section 
      className={"about"} 
      id='about'
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.8 }}
    >
      <motion.div 
        className={"container about-container"}
        initial={{ y: 50 }}
        whileInView={{ y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        <Specialties specialties={specialtiesData.map(specialty => ({
          ...specialty,
          name: t(`specialties.${specialty.id}.name`),
          description: t(`specialties.${specialty.id}.description`),
        }))} />
      </motion.div>
    </motion.section>
  );
}

export default AboutSection;