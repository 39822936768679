import { Email } from "./Email";
import { collection, addDoc } from "firebase/firestore"; 
import { FirestoreDB }  from "../../../../Firebase/FirestoreDB";

class EmailUploader {
  private emailCollection = collection(FirestoreDB, "emails");

  public async uploadEmail(email: Email) {
        try {
            const docRef = await addDoc( this.emailCollection, {
                from: email.from,
                "from-email": email.fromEmail,
                subject: email.subject,
                body: email.body,
                phone: email.phone,
                timestamp: new Date(),
                sent: false
            });

            console.log("Email uploaded with ID: ", docRef.id);
        } catch (error) {
            console.error("Error uploading email: ", error);
            throw error;
        }
    }
}

export default EmailUploader
