import { Email } from "./Email";

class EmailBuilder {
  private subject: string = '';
  private body: string = '';
  private to: string = '';
  private from: string = '';
  private fromEmail: string = '';
  private phone: string = '';

  setSubject(subject: string): EmailBuilder {
    this.subject = subject;
    return this;
  }

  setBody(body: string): EmailBuilder {
    this.body = body;
    return this;
  }

  setTo(to: string): EmailBuilder {
    this.to = to;
    return this;
  }

  setFrom(from: string): EmailBuilder {
    this.from = from;
    return this;
  }

  setFromEmail(fromEmail: string): EmailBuilder {
    this.fromEmail = fromEmail;
    return this;
  }

  setPhone(phone: string): EmailBuilder {
    this.phone = phone;
    return this;
  }

  build(): Email {
    return {
      subject: this.subject,
      body: this.body,
      to: this.to,
      from: this.from,
      fromEmail: this.fromEmail,
      phone: this.phone
    };
  }
}

export default EmailBuilder;
