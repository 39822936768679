import React from "react";
import "./promo-section.scss";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

function PromoSection() {
  const { t } = useTranslation();

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const fadeInUp = {
    initial: { opacity: 0, y: 60 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  return (
    <motion.section 
      className="promo" 
      id="promo" 
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/landing.jpg)`,
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <motion.div 
        className="promo-blur"
        initial={{ backdropFilter: "blur(0px)" }}
        animate={{ backdropFilter: "blur(8px)" }}
        transition={{ duration: 1, delay: 0.5 }}
      >
        <div className="promo-container">
          <div className="promo-row">
            <div className="col-md-8">
              <motion.div 
                className="promo-text-box"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.3 }}
              >
                <motion.div 
                  className="practice-identity"
                  {...fadeInUp}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <motion.h1 
                    className="practice-name"
                    {...fadeInUp}
                    transition={{ duration: 0.6, delay: 0.6 }}
                  >
                    {t('promoSection.practiceName')}
                  </motion.h1>
                  <motion.h2 
                    className="doctor-name"
                    {...fadeInUp}
                    transition={{ duration: 0.6, delay: 0.8 }}
                  >
                    {t('promoSection.doctorName')}
                  </motion.h2>
                </motion.div>
                <motion.p 
                  className="welcome-message"
                  {...fadeInUp}
                  transition={{ duration: 0.6, delay: 1 }}
                >
                  {t('promoSection.welcomeMessage')}
                </motion.p>
                <motion.p 
                  className="promo-desc"
                  {...fadeInUp}
                  transition={{ duration: 0.6, delay: 1.2 }}
                >
                  {t('promoSection.description')}
                </motion.p>
                <motion.button 
                  className="btn btn-secondary btn-light promo-btn" 
                  onClick={() => scrollToSection("contact")}
                  whileHover={{ scale: 1.05, backgroundColor: "#fff" }}
                  whileTap={{ scale: 0.95 }}
                  {...fadeInUp}
                  transition={{ duration: 0.6, delay: 1.4 }}
                >
                  {t('promoSection.buttonText')}
                </motion.button>
              </motion.div>
            </div>
            <div className="col-md-4">
            </div>
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
}

export default PromoSection;