import React from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSelector.scss';
import { motion } from 'framer-motion';

const LanguageSelector: React.FC = () => {
  const { i18n, t } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <nav 
      className="language-switcher" 
      role="navigation" 
      aria-label={t('accessibility.languageSelector')}
    >
      <button
        onClick={() => changeLanguage('en')}
        className={i18n.language === 'en' ? 'active' : ''}
        aria-label={t('accessibility.switchToEnglish')}
        aria-pressed={i18n.language === 'en'}
        title={t('accessibility.switchToEnglish')}
      >
        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          <img 
            src={`${process.env.PUBLIC_URL}/usa.png`} 
            alt=""  
            width="24" 
            height="24" 
            role="presentation"
          />
          <span className="sr-only">English</span>
        </motion.div>
      </button>
      <span 
        className="divider" 
        aria-hidden="true"
      >
        |
      </span>
      <button
        onClick={() => changeLanguage('fr')}
        className={i18n.language === 'fr' ? 'active' : ''}
        aria-label={t('accessibility.switchToFrench')}
        aria-pressed={i18n.language === 'fr'}
        title={t('accessibility.switchToFrench')}
      >
        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          <img 
            src={`${process.env.PUBLIC_URL}/france.png`} 
            alt="" 
            width="24" 
            height="24"
            role="presentation"
          />
          <span className="sr-only">Français</span>
        </motion.div>
      </button>
    </nav>
  );
};

export default LanguageSelector;
