import React, { useState } from "react";
import EmailUploader from "./Email/EmailUploader";
import EmailBuilder from "./Email/EmailBuilder";
import "./contact-section.scss";
import Modal from "../../Modals/Modal";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { motion } from "framer-motion";

interface ContactFormValues {
  name: string;
  email: string;
  messageBody: string;
  phone: string
}

interface RecaptchaResponse {
  success: boolean;
  score: number;
  reasons: string[];
  assessment_name: string;
}

const ContactForm: React.FC = () => {
  const emailUploader = new EmailUploader();
  const { t } = useTranslation();

  const recaptchaRef = React.useRef<ReCAPTCHA>();

  const [formValues, setFormValues] = useState<ContactFormValues>({
    name: "",
    email: "",
    messageBody: "",
    phone: ""
  });
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
  const [isPrivacyModalOpen, setisPrivacyModalOpen] = useState(false);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handlePrivacyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPrivacyChecked(event.target.checked);
  };

  const handleSubmitWithReCAPTCHA = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  
    try {
      // Reset and get new token
      recaptchaRef?.current?.reset();
      const token = await recaptchaRef?.current?.executeAsync();
      
      if (!token) {
        setError(t("contactForm.captchaError"));
        return;
      }
  
      // Verify token and get score
      const score = await getRecaptchaAssessment(token);
      
      if (!score || score < 0.5) {
        setError(t("contactForm.captchaLowScoreError"));
        return;
      }
  
      console.log("reCAPTCHA score passed with:", score);
  
      // Build and send email
      const email = new EmailBuilder()
        .setSubject("Website Query From " + formValues.name)
        .setBody(formValues.messageBody)
        .setFrom(formValues.name)
        .setFromEmail(formValues.email)
        .setPhone(formValues.phone)
        .build();
  
      await emailUploader.uploadEmail(email);
  
      // Reset form on success
      setSuccess(t("contactForm.successMessage"));
      setFormValues({ name: "", email: "", messageBody: "", phone: ""});
      setError(null);
      
    } catch (error) {
      console.error("Form submission error:", error);
      setError(t("contactForm.errorMessage"));
      setSuccess(null);
    }
  };
  
  async function getRecaptchaAssessment(token: string): Promise<number | null> {
    try {
      const response = await fetch(
        'https://europe-central2-pom-medical-site-440818.cloudfunctions.net/verify-recaptcha',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token,
            action: 'submit'
          })
        }
      );
  
      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(
          `Verification failed: ${errorData?.error || response.statusText}`
        );
      }
  
      const data: RecaptchaResponse = await response.json();
  
      if (!data.success) {
        throw new Error('Verification failed');
      }
  
      return data.score;
  
    } catch (error) {
      console.error("reCAPTCHA verification error:", error);
      throw error;
    }
  }

  return (
    <motion.div 
      className="form-container"
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
    >
      <motion.h1
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        {t("contactForm.title")}
      </motion.h1>
      <motion.p
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.3 }}
      >
        {t("contactForm.description")}
      </motion.p>

      <motion.form 
        className="form"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.4 }}
        onSubmit={handleSubmitWithReCAPTCHA}
        role="form"
        aria-label={t("contactForm.title")}
      >
        <motion.div 
          className="row"
          initial={{ opacity: 0, x: -20 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <div className="input-container">
            <label htmlFor="form-name" className="sr-only">{t("contactForm.namePlaceholder")}</label>
            <input
              type="text"
              id="form-name"
              name="name"
              className="form-input"
              placeholder={t("contactForm.namePlaceholder")}
              value={formValues.name}
              onChange={handleChange}
              required
              aria-required="true"
              aria-invalid={formValues.name === ""}
              aria-describedby="name-error"
            />
            {formValues.name === "" && (
              <span id="name-error" className="error-message sr-only">
                {t("contactForm.nameRequired")}
              </span>
            )}
          </div>
          <div className="input-container">
            <label htmlFor="form-email" className="sr-only">{t("contactForm.emailPlaceholder")}</label>
            <input
              type="email"
              id="form-email"
              name="email"
              placeholder={t("contactForm.emailPlaceholder")}
              className="form-input"
              value={formValues.email}
              onChange={handleChange}
              required
              aria-required="true"
              aria-invalid={formValues.email === ""}
              aria-describedby="email-error"
            />
            {formValues.email === "" && (
              <span id="email-error" className="error-message sr-only">
                {t("contactForm.emailRequired")}
              </span>
            )}
          </div>
        </motion.div>
        <motion.div 
          className="row"
          initial={{ opacity: 0, x: -20 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          <div className="input-container">
            <label htmlFor="form-phone" className="sr-only">{t("contactForm.phonePlaceholder")}</label>
            <input
              type="tel"
              id="form-phone"
              name="phone"
              placeholder={t("contactForm.phonePlaceholder")}
              className="form-input"
              value={formValues.phone}
              onChange={handleChange}
              required
              aria-required="true"
              aria-invalid={formValues.phone === ""}
              aria-describedby="phone-error"
            />
            {formValues.phone === "" && (
              <span id="phone-error" className="error-message sr-only">
                {t("contactForm.phoneRequired")}
              </span>
            )}
          </div>  
        </motion.div>
        <motion.div 
          className="row"
          initial={{ opacity: 0, x: -20 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.7 }}
        >
          <div className={"input-container"}>
            <label htmlFor="form-messageBody" className="sr-only">{t("contactForm.messagePlaceholder")}</label>
            <textarea
              id="form-messageBody"
              name="messageBody"
              className="form-input"
              placeholder={t("contactForm.messagePlaceholder")}
              value={formValues.messageBody}
              onChange={handleChange}
              required
              maxLength={500}
              aria-required="true"
              aria-invalid={formValues.messageBody === ""}
              aria-describedby="message-error"
            />
            {formValues.messageBody === "" && (
              <span id="message-error" className="error-message sr-only">
                {t("contactForm.messageRequired")}
              </span>
            )}
          </div>
        </motion.div>

        <motion.div 
          className="row"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.8 }}
        >
          <div className="input-container">
            <input
              type="checkbox"
              id="privacy-policy"
              name="privacyPolicy"
              checked={isPrivacyChecked}
              onChange={handlePrivacyChange}
              required
              aria-required="true"
              aria-describedby="privacy-error"
            />
            <label htmlFor="privacy-policy">
              {t("contactForm.privacyPolicyAgreement")}
              <button
                type="button"
                className="privacy-link"
                onClick={() => setisPrivacyModalOpen(true)}
                aria-label={t("contactForm.privacyPolicyLink")}
              >
                {t("contactForm.privacyPolicyLink")}
              </button>
            </label>
            {!isPrivacyChecked && (
              <span id="privacy-error" className="error-message sr-only">
                {t("contactForm.privacyRequired")}
              </span>
            )}
          </div>

          <motion.button
            type="submit"
            disabled={!isPrivacyChecked}
            className={isPrivacyChecked ? "form-submit-enabled form-submit" : "form-submit"}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.9 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            aria-disabled={!isPrivacyChecked}
          >
            {t("contactForm.submitButton")}
          </motion.button>

          {error && (
            <motion.div 
              className="global-message error"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              role="alert"
              aria-live="assertive"
            >
              <div className="icon" aria-hidden="true">
                <FaTimesCircle />
              </div>
              <div className="message">{error}</div>
            </motion.div>
          )}

          {success && (
            <motion.div 
              className="global-message success"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              role="status"
              aria-live="polite"
            >
              <div className="icon" aria-hidden="true">
                <FaCheckCircle />
              </div>
              <div className="message">{success}</div>
            </motion.div>
          )}
        </motion.div>
        
        <ReCAPTCHA
            ref={recaptchaRef as React.RefObject<ReCAPTCHA>}
          size="invisible"
            sitekey="6Leyj3oqAAAAAHjtP64T-tKKB8e0sOFC4XzgNLqD"
            data-action="submit"
            aria-hidden="true"
        />
      </motion.form>

      <Modal isOpen={isPrivacyModalOpen} onClose={() => setisPrivacyModalOpen(false)}>
        <h2>{t("legal.privacy.title")}</h2>
        <p>{t("legal.lastUpdated")}</p>
        
        <div>
          <h3>{t("legal.privacy.sections.collection.title")}</h3>
          <p>{t("legal.privacy.sections.collection.content")}</p>
          <ul>
            {(t("legal.privacy.sections.collection.items", { returnObjects: true }) as string[]).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>

        <div>
          <h3>{t("legal.privacy.sections.usage.title")}</h3>
          <p>{t("legal.privacy.sections.usage.content")}</p>
          <ul>
            {(t("legal.privacy.sections.usage.items", { returnObjects: true }) as string[]).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>

        <div>
          <h3>{t("legal.privacy.sections.storage.title")}</h3>
          <h4>{t("legal.privacy.sections.storage.subtitle.storage")}</h4>
          <ul>
            {(t("legal.privacy.sections.storage.storage_details", { returnObjects: true }) as string[]).map((detail, index) => (
              <li key={index}>{detail}</li>
            ))}
          </ul>
          <h4>{t("legal.privacy.sections.storage.subtitle.security")}</h4>
          <p>{t("legal.privacy.sections.storage.security_content")}</p>
        </div>

        <div>
          <h3>{t("legal.privacy.sections.sharing.title")}</h3>
          <p>{t("legal.privacy.sections.sharing.content")}</p>
          <ul>
            {(t("legal.privacy.sections.sharing.services", { returnObjects: true }) as string[]).map((service, index) => (
              <li key={index}>{service}</li>
            ))}
          </ul>
          <p>{t("legal.privacy.sections.sharing.additional")}</p>
        </div>

        <div>
          <h3>{t("legal.privacy.sections.rights.title")}</h3>
          <p>{t("legal.privacy.sections.rights.content")}</p>
          <ul>
            {(t("legal.privacy.sections.rights.items", { returnObjects: true }) as string[]).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>

        <div>
          <h3>{t("legal.privacy.sections.children.title")}</h3>
          <p>{t("legal.privacy.sections.children.content")}</p>
        </div>

        <div>
          <h3>{t("legal.privacy.sections.changes.title")}</h3>
          <p>{t("legal.privacy.sections.changes.content")}</p>
        </div>

        <div>
          <h3>{t("legal.privacy.sections.contact.title")}</h3>
          <p>{t("legal.privacy.sections.contact.content")}</p>
        </div>
      </Modal>
    </motion.div>
  );
};

export default ContactForm;