import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./footer-section.scss";
import Modal from "../../Modals/Modal";

const FooterSection: React.FC = () => {
  const { t } = useTranslation();
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
          <h3>{t("footer.companyName")}</h3>
        </div>
        <div className="footer-links">
          <ul>
            <li>
              <button 
                type="button"
                className="footer-link" 
                onClick={() => scrollToSection("contact")}
              >
                {t("footer.contact")}
              </button>
            </li>
            <li>
            <button
              type="button"
              className="footer-link"
              onClick={() => setIsTermsModalOpen(true)}
            >
                {t("footer.terms")}
            </button>
            </li>
            <li>
              <button
                type="button"
                className="footer-link"
                onClick={() => setIsPrivacyModalOpen(true)}
              >
                {t("footer.privacy")}
              </button>
            </li>
          </ul>
        </div>
        <div className="footer-social">
          <a href="https://facebook.com" className="social-link" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://twitter.com" className="social-link" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://instagram.com" className="social-link" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} {t("footer.companyName")}. {t("footer.allRightsReserved")}</p>
        <div className="recaptcha-disclaimer">
          <span>{t("footer.recaptchaProtected")} </span>
          <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
            {t("footer.privacyPolicy")}
          </a>
          <span> {t("footer.and")} </span>
          <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
            {t("footer.termsOfService")}
          </a>
          <span> {t("footer.apply")}</span>
        </div>
      </div>

      <Modal isOpen={isPrivacyModalOpen} onClose={() => setIsPrivacyModalOpen(false)}>
        <h2>{t("legal.privacy.title")}</h2>
        <p>{t("legal.lastUpdated")}</p>
        
        <div>
          <h3>{t("legal.privacy.sections.collection.title")}</h3>
          <p>{t("legal.privacy.sections.collection.content")}</p>
          <ul>
            {(t("legal.privacy.sections.collection.items", { returnObjects: true }) as string[]).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>

        <div>
          <h3>{t("legal.privacy.sections.usage.title")}</h3>
          <p>{t("legal.privacy.sections.usage.content")}</p>
          <ul>
            {(t("legal.privacy.sections.usage.items", { returnObjects: true }) as string[]).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>

        <div>
          <h3>{t("legal.privacy.sections.storage.title")}</h3>
          <h4>{t("legal.privacy.sections.storage.subtitle.storage")}</h4>
          <ul>
            {(t("legal.privacy.sections.storage.storage_details", { returnObjects: true }) as string[]).map((detail, index) => (
              <li key={index}>{detail}</li>
            ))}
          </ul>
          <h4>{t("legal.privacy.sections.storage.subtitle.security")}</h4>
          <p>{t("legal.privacy.sections.storage.security_content")}</p>
        </div>

        <div>
          <h3>{t("legal.privacy.sections.sharing.title")}</h3>
          <p>{t("legal.privacy.sections.sharing.content")}</p>
          <ul>
            {(t("legal.privacy.sections.sharing.services", { returnObjects: true }) as string[]).map((service, index) => (
              <li key={index}>{service}</li>
            ))}
          </ul>
          <p>{t("legal.privacy.sections.sharing.additional")}</p>
        </div>

        <div>
          <h3>{t("legal.privacy.sections.rights.title")}</h3>
          <p>{t("legal.privacy.sections.rights.content")}</p>
          <ul>
            {(t("legal.privacy.sections.rights.items", { returnObjects: true }) as string[]).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>

        <div>
          <h3>{t("legal.privacy.sections.children.title")}</h3>
          <p>{t("legal.privacy.sections.children.content")}</p>
        </div>

        <div>
          <h3>{t("legal.privacy.sections.changes.title")}</h3>
          <p>{t("legal.privacy.sections.changes.content")}</p>
        </div>

        <div>
          <h3>{t("legal.privacy.sections.contact.title")}</h3>
          <p>{t("legal.privacy.sections.contact.content")}</p>
        </div>
      </Modal>
      <Modal isOpen={isTermsModalOpen} onClose={() => setIsTermsModalOpen(false)}>
        <h2>{t("legal.terms.title")}</h2>
        <p>{t("legal.lastUpdated")}</p>

        <div>
          <h3>{t("legal.terms.sections.acceptance.title")}</h3>
          <p>{t("legal.terms.sections.acceptance.content")}</p>
        </div>

        <div>
          <h3>{t("legal.terms.sections.description.title")}</h3>
          <p>{t("legal.terms.sections.description.content")}</p>
        </div>

        <div>
          <h3>{t("legal.terms.sections.responsibilities.title")}</h3>
          <p>{t("legal.terms.sections.responsibilities.content")}</p>
          <ul>
            {(t("legal.terms.sections.responsibilities.items", { returnObjects: true }) as string[]).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>

        <div>
          <h3>{t("legal.terms.sections.medical.title")}</h3>
          <p>{t("legal.terms.sections.medical.content")}</p>
        </div>

        <div>
          <h3>{t("legal.terms.sections.liability.title")}</h3>
          <p>{t("legal.terms.sections.liability.content")}</p>
        </div>

        <div>
          <h3>{t("legal.terms.sections.changes.title")}</h3>
          <p>{t("legal.terms.sections.changes.content")}</p>
        </div>

        <div>
          <h3>{t("legal.terms.sections.law.title")}</h3>
          <p>{t("legal.terms.sections.law.content")}</p>
        </div>
      </Modal>
    </footer>
  );
};

export default FooterSection;
