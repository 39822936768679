import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
}

const SEO: React.FC<SEOProps> = ({ 
  title, 
  description, 
  image = "%PUBLIC_URL%/hypertension.png" 
}) => {
  const { t, i18n } = useTranslation();

  const seoTitle = title || t('seo.title');
  const seoDescription = description || t('seo.description');

  return (
    <Helmet
      htmlAttributes={{ lang: i18n.language }}
      title={seoTitle}
      meta={[
        // Primary Meta Tags
        { name: 'title', content: seoTitle },
        { name: 'description', content: seoDescription },
        { name: 'keywords', content: t('seo.keywords') },
        { name: 'theme-color', content: '#00568b' },

        // Open Graph / Facebook
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://docmarenco.com/' },
        { property: 'og:title', content: t('seo.ogTitle') },
        { property: 'og:description', content: t('seo.ogDescription') },
        { property: 'og:image', content: image },

        // Twitter
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: seoTitle },
        { name: 'twitter:description', content: seoDescription },
        { name: 'twitter:image', content: image }
      ]}
      link={[
        // Alternate language links
        { 
          rel: 'alternate', 
          hrefLang: 'en', 
          href: 'https://docmarenco.com/en' 
        },
        { 
          rel: 'alternate', 
          hrefLang: 'fr', 
          href: 'https://docmarenco.com/fr' 
        }
      ]}
    />
  );
};

export default SEO;