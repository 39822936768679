import React, { useState } from "react";
import "./header.scss";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

function Header() {
  const { t } = useTranslation();
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    setIsMobileNavOpen(false);
  };

  const menuItems = [
    { id: "updates", text: t("header.newsUpdates") },
    { id: "about", text: t("header.aboutUs") },
    { id: "contact", text: t("header.contactUs") },
    { id: "find", text: t("header.findUs") }
  ];

  return (
    <header 
      className="header"
    >
      {/* Desktop Navigation */}
      <div className="header-container">
        <div className="header-inner">
          <motion.div 
            className="logo"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <button onClick={() => scrollToSection("promo")}>
              <img 
                src={`${process.env.PUBLIC_URL}/logo192.png`}
                alt="Logo"
              />
            </button>
          </motion.div>

          <div className="header-navigation">
            <nav className="navigation-main" role="navigation" aria-label="Main navigation">
              <div className="primary-menu-container">
                <ul className="primary-menu">
                  {menuItems.map((item, index) => (
                    <motion.li 
                      key={item.id}
                      className="primary-menu-item"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ 
                        delay: index * 0.1,
                        type: "spring",
                        stiffness: 100
                      }}
                    >
                      <motion.button 
                        onClick={() => scrollToSection(item.id)}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        {item.text}
                      </motion.button>
                    </motion.li>
                  ))}
                </ul>
              </div>
            </nav>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              <LanguageSelector />
            </motion.div>
          </div>
        </div>
      </div>

      {/* Mobile Navigation Button */}
      <div 
        className="header-mobile-button"
      >
        <button
          className={`header__navigation-btn-menu btn-primary btn ${isMobileNavOpen ? "active" : ""}`}
          onClick={toggleMobileNav}
        >
          <div className="header__navigation-btn-menu--inner">
            <img 
              src={`${process.env.PUBLIC_URL}/stack.png`}
              alt="Menu"
            />
          </div>
        </button>
      </div>

      {/* Mobile Navigation Modal */}
      {isMobileNavOpen && (
        <motion.div 
          className="mobile-nav-modal active"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <motion.div 
            className="mobile-nav-content open"
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: 'tween', duration: 0.3 }}
          >
            <motion.div 
              className="mobile-nav-header"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
            >
              <motion.div 
                className="logo"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <button onClick={() => {
                  scrollToSection("promo");
                  setIsMobileNavOpen(false);
                }}>
                  <img 
                    src={`${process.env.PUBLIC_URL}/logo192.png`}
                    alt="Logo"
                  />
                </button>
              </motion.div>
              <motion.button 
                className="mobile-nav-close"
                onClick={toggleMobileNav}
                whileTap={{ scale: 0.9 }}
              >
                ✕
              </motion.button>
            </motion.div>
            <nav className="mobile-navigation">
              <ul>
                {menuItems.map((item, index) => (
                  <motion.li 
                    key={item.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ 
                      delay: index * 0.1,
                      type: "spring",
                      stiffness: 100
                    }}
                  >
                    <motion.button 
                      onClick={() => scrollToSection(item.id)}
                      whileTap={{ scale: 0.95 }}
                    >
                      {item.text}
                    </motion.button>
                  </motion.li>
                ))}
              </ul>
            </nav>
            <motion.div 
              className="mobile-language-selector"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              <LanguageSelector />
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </header>
  );
}

export default Header;