import React from 'react';
import './banner.scss';
import { motion } from 'framer-motion';

interface BannerProps {
  notices: { title: string; date: string; message: string }[];
}

const Banner: React.FC<BannerProps> = ({ notices }) => {
  return (
    <div className="notice-banner">
      <div className="banner-container">
        {notices.map((notice, index) => (
          <motion.div 
            key={`notice-${index}`} 
            className="banner"
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true, margin: "-50px" }}
            transition={{ 
              duration: 0.5,
              delay: 0.2 * (index + 1),
              type: "spring",
              stiffness: 100
            }}
            whileHover={{ 
              scale: 1.02,
              boxShadow: "0 8px 24px rgba(0,0,0,0.1)"
            }}
          >
            <motion.p 
              className="banner-date"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 * (index + 1) }}
            >
              {notice.date}
            </motion.p>
            <motion.h2 
              className="banner-title"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 * (index + 1) }}
            >
              {notice.title}
            </motion.h2>
            <motion.p 
              className="banner-message"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 * (index + 1) }}
            >
              {notice.message}
            </motion.p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Banner;
